// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

var $ = require('jquery');
var Link = require('../_modules/link/link');

$(function() {
  new Link(); // Activate Link modules logic
  console.log('Welcome to Yeogurt!');
});

$(function() {
  $( "#loginBtn" ).click(function() {
    $( "#loginBtn" ).addClass( "onclic", 250, validate);
  });

  function validate() {
    setTimeout(function() {
      $( "#loginBtn" ).removeClass( "onclic" );
      $( "#loginBtn" ).addClass( "validate", 450, callback );
    }, 2250 );
  }
    function callback() {
      setTimeout(function() {
        $( "#loginBtn" ).removeClass( "validate" );
      }, 1250 );
    }
});

// NAV

var PNLNav = PNLNav || {};

console.log('PNLNav');
PNLNav.Site = {
    busy: false,

    //Resize
    currentWidth: undefined,

    init: function() {
      setTimeout(function() {
        PNLNav.Site.currentWidth = $(window).width();
        PNLNav.Site.fixedHeader();
        PNLNav.Site.toggleMobileMenu();
      }, 10);
    },

    fixedHeader: function() {

      console.log('fix Header');

      var header = $('#globalNav_header'); 
      var lastScrollTop = 0;

      function setHeader() {
        // 1
        var windowHeight = $(window).height();
        // 2
        var siteHeight = $('body').outerHeight();
        // 3
        var scrollMargin = (siteHeight - windowHeight);
        // 4
        var scrollTop = $(document).scrollTop();
        // 5 
        if (scrollTop > lastScrollTop){
          if (scrollTop > 50) {
            header.addClass('removed');
            console.log('removed');
          }
        }
        // 6
        else {
          if (scrollTop < scrollMargin) {
            header.removeClass('removed');
          }
        }

        lastScrollTop = scrollTop;
        
      }

      $('body').scroll(function(){
        setHeader();
      });

      $(window).scroll(function(){
        setHeader();
      });

      var windowHeight = $(window).height();
      // to do fix .site to body
      var siteHeight = $('.site').outerHeight();

      if (siteHeight <= windowHeight) {
        header.removeClass('removed');
      }
    },

    toggleMobileMenu: function() {
      console.log('init toggle mobile menu');
        //var header = $('#PostNL_globalNav #PostNL_globalNav_header');
        var header = $('#globalNav_header');
        var navButton = header.find('.mobile-nav');
        var body = $('body');
        var scrollPos;


        navButton.click(function() {
          console.log('click on mobile nav button');
          if(!header.hasClass('show-menu-left')) {
            header.addClass('show-menu-left');
            scrollPos = $(window).scrollTop();
            body.addClass('menu-open');
            PNLNav.Site.activeMenuItem = header.find('nav').find('.active');
          }
          else {
            header.removeClass('show-menu-left');
            header.find('nav').find('.active').removeClass('active');
            body.removeClass('menu-open');
            window.scrollTo(0, scrollPos);
            PNLNav.Site.activeMenuItem.addClass('active');
          }

          if(header.hasClass('show-menu-right')) {
            header.removeClass('show-menu-right');
          }
        });
      },
}

$(document).ready(PNLNav.Site.init);